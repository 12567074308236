import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="The Pizza Room Customer Story - Rotaready"
          description="Learn how The Pizza Room has been able to better deploy over 20 hours each week since using Rotaready, and achieve an ROI of 572%"
          url="customer-stories/hospitality-the-pizza-room"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Multiple people grabbing at slides of a Hawaiian pizza"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="The Pizza Room logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Learn how The Pizza Room has been able to better deploy over 20 hours each week since using Rotaready, and achieve an ROI of 572%" />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="7" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="50" />
              <StatSubtitle text="employees" />
            </StatItem>

            <StatItem>
              <StatHeadline text="572%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="20 hours" />
              <StatSubtitle text="better deployed every week!" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="The Pizza Room" />
              </Content>

              <Content>
                <TextBlock text="Providing authentic Italian pizzeria vibes across seven London-based venues, The Pizza Room team delivers the finest quality Italian ingredients to a thriving pizza-loving community." />
              </Content>

              <Content>
                <TextBlock text="As the business has grown, so too has The Pizza Room team, with more than fifty employees working across five pizza parlours and two coffee shops. The growing operation meant that their old, manual staff scheduling process was no longer fitting the bill. They began looking for a new solution that would enable them to automate processes to save time and integrate with their existing EPoS and payroll systems." />
              </Content>

              <Content>
                <Blockquote
                  text="For a long time, our business relied on manual methods for scheduling rotas and tracking attendance, which meant lots of time spent on manual data entry and limited functionality to meet our specific needs. Rotaready has helped us to streamline these processes and save us hours of time."
                  attribution="Andrea Pitini, Project Manager"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Dough-licious decisions" />
              </Content>

              <Content>
                <TextBlock text="With manual staff scheduling and payroll admin taking up more and more time as the team grew, the hunt was on for a solution that would meet their needs. After reviewing a number of options, it was the attractive price point and rich feature set that sold Rotaready to the Pizza Room's team." />
              </Content>

              <Content>
                <Blockquote
                  text="When evaluating our options, our primary criteria were features and price. The robust cost control functionality really stood out to me as a key feature that aligned perfectly with our needs as well as the user-friendly platform interface. Ultimately, the combination of comprehensive features and competitive pricing made the decision to go with Rotaready a clear choice for us."
                  attribution="Andrea Pitini, Project Manager"
                />
              </Content>

              <Content>
                <TextBlock text="Rotaready's powerful cost control functionality is a particular favourite of The Pizza Rooms's team, and immediately enabled them to manage their costs more effectively due to the visibility they gained over costs across the business." />
              </Content>

              <Content>
                <Blockquote
                  text="When we began our partnership with Rotaready, our primary focus was on effectively managing staffing costs. We were particularly interested in the cost control feature, which allowed us to monitor and optimise these. Additionally, the ability to input sales forecasts and utilise automated forecasting tools was crucial for us. Real-time monitoring of wage percentages and expenditures further solidified Rotaready as the preferred choice. Its ease of use also made the transition smooth and intuitive, ultimately driving our decision to adopt Rotaready."
                  attribution="Andrea Pitini, Project Manager"
                />
              </Content>

              <Content>
                <TextBlock text="The implementation of Rotaready proved to be a swift and efficient experience for The Pizza Room. From the setup of their sites to the integration with their EPoS and payroll software, every step was streamlined and straightforward. Integration with their EPoS system was handled by the Rotaready team, resulting in a stable and reliable connection. Additionally, the setup of their payroll software proved to be relatively simple, thanks to a structured mapping process that ensured a seamless roll-out." />
              </Content>

              <Content>
                <Blockquote
                  text="The implementation process with Rotaready was fast and efficient, simplifying our operations from the get-go."
                  attribution="Andrea Pitini, Project Manager"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Slicing costs and delivering efficiency" />
              </Content>

              <Content>
                <TextBlock text="The implementation of Rotaready has marked a significant shift in operational efficiency for the team, enabling them to complete tasks that used to take days in minutes. We asked them what their favourite things are about Rotaready that have been helping them transform operations at The Pizza Room." />
              </Content>

              <Content>
                <H4 uistyle="primary" text="1. Cost control" />
              </Content>

              <Content>
                <TextBlock text="Keeping a close eye on costs is essential for The Pizza Room team, which is why the Cost Control feature has been invaluable. With this tool, they can easily manage and optimise labour costs, ensuring every pound spent contributes to their bottom line. The ability to forecast sales and control labour spend has been instrumental in maximising productivity while staying within budget." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready's Cost Control feature has given us better visibility across our sites so we can ensure that we have the optimal staffing levels while keeping a tight grasp on costs."
                  attribution="Andrea Pitini, Project Manager"
                />
              </Content>

              <Content>
                <H4 uistyle="primary" text="2. Product roadmap" />
              </Content>

              <Content>
                <TextBlock text="The Pizza Room team knows that staying ahead of the curve is key to delivering exceptional service, and that goes for software as well as pizza. While not a feature in itself, Andrea Pitini, Project Manager at The Pizza Room was keen to explain why he loves the visibility he has of Rotaready's Product Roadmap; shared with all Rotaready customers to keep them up to date on all the latest product features and improvements. This transparency not only keeps them informed but also allows them to anticipate and prepare for changes, ensuring a seamless transition to new functionalities." />
              </Content>

              <Content>
                <Blockquote
                  text="Having visibility of Rotaready's product roadmap gives us confidence that we're always moving forward, adapting to new industry trends, and staying ahead of the competition."
                  attribution="Andrea Pitini, Project Manager"
                />
              </Content>

              <Content>
                <H4 uistyle="primary" text="3. HR Platform" />
              </Content>

              <Content>
                <TextBlock text="Rotaready's HR functionality has been instrumental in helping The Pizza Rooms effectively manage their people processes and maintain a happy and productive team. The self-onboarding functionality enables employees to input all key information and documentation via the mobile app when they start their employment, saving a huge amount of time for managers. Holiday management is now seamless, as is performance tracking, thanks to Rotaready's secure documentation functionality. A combination of all of these features has helped the team gain valuable time previously spent on administrative tasks, meaning they now have more time to focus on fostering a positive work environment." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready's HR platform helps us save time and manage people processes more effectively, allowing us to focus on what really matters - our team."
                  attribution="Andrea Pitini, Project Manager"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="The cost and time-saving features of Rotaready have helped The Pizza Room increase efficiency across rota scheduling and staff management and achieve an ROI of 572%." />
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="A couple sharing cocktails at an outdoor dining table"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Topping the charts" />
              </Content>

              <Content>
                <TextBlock text="The value of Rotaready to The Pizza Room team spans across the entire business, notably transforming payroll processing from a week-long task involving multiple staff members to a half-day task managed by one person. In addition, automated employee scheduling and time tracking has slashed time-consuming admin tasks from hours to seconds, significantly lightening managerial loads and enhancing efficiency. The notable reduction in labour hours has also translated into cost savings and heightened productivity across their venues." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready's impact has been remarkable, from streamlining payroll to automating scheduling tasks. It's not just about saving time, but how we can operate and grow the business more efficiently with our operations running smoothly."
                  attribution="Andrea Pitini, Project Manager"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Assorted slices of pizza being held up in the air"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_thepizzaroom"
                label="rotaready.com/customer-stories/hospitality-the-pizza-room"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-The-Pizza-Room.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/the-pizza-room/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/the-pizza-room/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 60, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/the-pizza-room/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/the-pizza-room/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
